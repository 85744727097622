import { gql } from 'urql'
import { InterviewDetailType } from '../types'

const QueryInterviewDetail = gql<
  { interviewsShow: InterviewDetailType },
  {
    id: number
  }
>`
  query ($id: Int!) {
    interviewsShow(id: $id) {
      id
      fromDatetime
      toDatetime
      timezone
      eventType
      eventTypeDescription
      meetingUrl
      previewLink
      location {
        id
        address
        city
        state
        country
      }
      profile {
        id
        fullName
        avatarVariants
        email
        applicants {
          id
          job {
            id
            title
          }
        }
      }
      organizer {
        id
        avatarVariants
        fullName
        defaultColour
        email
      }
      attendees {
        id
        avatarVariants
        fullName
        email
        defaultColour
        availableForSchedule
        roles {
          id
          name
        }
      }
      applicant {
        id
        job {
          id
          title
          slug
          owner {
            email
            fullName
          }
        }
      }
      remindSchedule
      remindFeedback
      job {
        id
        title
        owner {
          id
        }
        jobRecruiters {
          user {
            id
          }
        }
      }
      jobIkit {
        id
        name
      }
      jobStage {
        id
        stageLabel
      }
      currentUserFeedback {
        id
        overallFeedback
        status
      }
      ikitFeedbacksSummary
      state
      stateDescription
      interviewTimeSlots {
        fromDatetime
        toDatetime
        picked
        timezone
      }
      sendEmailToCandidate
      sendEmailToAttendees
      remindBeforeTime
      remindAfterTime
    }
  }
`

export default QueryInterviewDetail
